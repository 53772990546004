body {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.mod-setting-tag-new {
  font-weight: bold;
  color: red;
  font-size: 13px;
}

.mod-setting-name {
  font-weight: bold;
}

.mod-setting-desc {
  max-width: 360px;
  font-size: 14px;
  font-style: italic;
  margin-top: 2px;
}

/**
 * Main wrapper
 */
.select-search {
  width: 300px;
  position: relative;
  font-family: "Nunito Sans", sans-serif;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
 * Value wrapper
 */
.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__value::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
 * Input
 */
.select-search__input {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 40px 0 16px;
  background: #fff;
  border: 1px solid transparent;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  outline: none;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
 * Options wrapper
 */
.select-search__select {
  background: #fff;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

/**
 * Options
 */
.select-search__options {
  list-style: none;
}

/**
 * Option row
 */
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
 * Option
 */
.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: #2fcc8b;
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #2eb378;
  color: #fff;
}

/**
 * Group
 */
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
 * States
 */
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
 * Modifiers
 */
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: #2fcc8b;
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}

.react-tagsinput-input {
  width: 100%;
}

.tooltip {
  width: 100px;
}

.mp-inbound-cart-item {
  margin-bottom: 10px;
  border: 1px solid #c5c5c5;
  background: #eaecef;
  padding: 10px;
  color: #000;
}

.mp-inbound-cart-item-accepted {
  margin-bottom: 10px;
  border: 1px solid #c5c5c5;
  background: #408558;
  padding: 10px;
  color: #fff;
}

.mp-inbound-cart-item-rejected {
  margin-bottom: 10px;
  border: 1px solid #c5c5c5;
  background: #cb444a;
  padding: 10px;
  color: #fff;
}

.mp-inbound-cart-item .title,
.mp-inbound-cart-item-accepted .title,
.mp-inbound-cart-item-rejected .title {
  font-weight: bold;
  margin-bottom: 5px;
}

.mp-inbound-cart-item .asin,
.mp-inbound-cart-item-accepted .asin,
.mp-inbound-cart-item-rejected .asin {
  font-size: 14px;
}

.cc_dash_table_options_float_right {
  margin-left: 10px;
  padding-right: 10px;
}

div.dropdown-menu .nav-link {
  color: #000 !important;
}

.progress {
  border-radius: 0;
}

.flex-responsive {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  margin: 30px 0 10px 0;
  width: 100%;
}

.circle-progress-inner {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.benchmark {
  padding-left: 40px;
}

@media screen and (max-width: 500px) {
  .flex-responsive {
    display: block;
  }

  .benchmark {
    padding-left: 0;
  }

  .flex-responsive > div {
    width: 100% !important;
    border: none !important;
    padding: 0 !important;
    margin-top: 10px !important;
  }

  .circle-progress-inner {
    height: 100%;
    width: 100%;
  }

  .moduleProgressBar {
    width: 500px;
    height: 500px;
    margin-bottom: 10px;
  }

  .cc_dash_table_options_float_right {
    margin-left: 0px;
    padding-right: 0px;
    text-align: center;
    /* margin-bottom: 10px; */
  }
  .DateRangePicker,
  .DateRangePickerInput {
    width: 100%;
  }
}

input {
  outline: none !important;
  outline-width: 0 !important;
  outline-style: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
  outline-width: 0 !important;
  outline-style: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

*:focus {
  outline: none;
  outline-style: none;
}

.moduleProgressBar {
  width: 160px;
  height: 160px;
}

.moduleProgressBar:not(:last-child) {
  margin-right: 10px;
}

.tabTitle {
  text-align: center;
  width: 175px;
}

.page-dashboard .table td,
.page-dashboard .table th {
  padding: 0.21rem !important;
}

.page-dashboard .table td {
  font-family: sans-serif;
  font-size: 11px !important;
  text-align: center;
}

.page-dashboard .table th {
  font-size: 10px !important;
}
#usersTable th {
  font-size: 14px !important;
}

.tabTitleLabel,
.tabTitleLabel:hover {
  color: #007bff !important;
  font-size: 20px;
}
.tabTitleLabel:hover {
  color: green;
}
.disabledTitle,
.disabledTitle:hover {
  color: red !important;
  font-size: 20px;
}

.spanClickable {
  color: #007bff !important;
  cursor: pointer;
  margin-right: 15px;
}

.spanClickable:hover {
  text-decoration: underline;
}

.table_triggers {
  font-size: 14px;
}

.table-responsive {
  overflow-x: visible;
  max-width: 100%;
  font-size: 13px;
}

.dx-g-bs4-table {
  min-width: 0 !important;
  max-width: 100%;
}

.table td,
.table th {
  vertical-align: middle !important;
}

.actionButtonLessPadding {
  padding: 0.175rem 0.65rem !important;
}

.editModal {
  width: 750px;
  max-width: 750px;
}

.input-text-trigger-name,
.input-text-sound-name {
  border: none;
  font-size: 24px;
  border-radius: 0;
}

.input-text-trigger-name:focus,
.input-text-sound-name:focus {
  outline-width: 0;
  outline: none;
  box-shadow: none;
}

.form-control:focus {
  outline-width: 0;
  outline: none;
  box-shadow: none;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}

.tabletoolkit {
  margin-top: 20px;
}

.table td,
.table th {
  font-size: 13px;
}

.react-bootstrap-table {
  overflow-x: auto !important;
}

/* .dash_table_options_float_right {
  float: right;
} */
.dash_table_options_float_left {
  float: left;
}

.search-bar {
  text-align: left;
}

.dash_table_inputs {
  width: 100%;
  margin: 0 auto;
}

.div-scan-count {
  text-align: center;
  border: 1px solid #ccc;
  margin: 30px;
  padding: 10px 5px 10px 5px;
}

.div-scan-count > .scan-count {
  font-size: 32px;
  border-top: 1px solid #38383d;
  margin-top: 20px;
}

.div-scan-count > .reject-count {
  font-size: 14px;
  color: #f20050;
  border-top: 1px solid #dddddd;
  margin-top: 5px;
  padding-top: 8px;
}

.div-scan-count-overall {
  text-align: center;
  border: 1px solid #ccc;
  margin: 4px;
  padding: 10px 5px 10px 5px;
}

.div-scan-count-overall > .scan-count-overall {
  font-size: 32px;
  border-top: 1px solid #38383d;
  margin-top: 20px;
}

.div-scan-count-overall > .reject-count-overall {
  font-size: 14px;
  color: #0444bf;
  border-top: 1px solid #dddddd;
  margin-top: 5px;
  padding-top: 8px;
}

@media only screen and (max-width: 1024px) {
  .dash_table_options_float_right,
  .dash_table_options_float_left {
    float: none;
    text-align: center;
  }

  .search-bar {
    text-align: center;
  }

  .dash_table_inputs {
    width: 100%;
    text-align: center;
  }

  .div-searchbar label {
    width: 100%;
  }
}
